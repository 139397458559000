import React from "react";
import styled from "@emotion/styled";
import { css, Global } from "@emotion/react";

const App: React.FC = () => {
  return (
    <div>
      <Global
        styles={css`
          body {
            margin: 0;
            background: gray;
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        `}
      />
      Is Waikane open?
      <Status>Open</Status>
    </div>
  );
};

const Status = styled.div`
  color: white;
  font-size: 36pt;
  background-color: green;
  padding: 400px;
  border-radius: 800px;
  /* width: ${window.innerHeight - 200}px;
  height: ${window.innerHeight - 200}px; */
`;

export default App;
